import moment from 'moment-timezone';
import Moment from 'moment-timezone';
import {request} from "../../util/Request";
import Error from "../../util/Error";

const DEFAULT_CHART_FORM_STATE = {
    year: moment().format('Y'),
    month: moment().format('MM'),
    countries: [],
    models: [],
    brands: [],
    method: '_post',
    type: 'next',
    start_date: moment().format('YYYY-MM-DD'),
}

export default {
    data() {
        return {
            chartFormFields: {...DEFAULT_CHART_FORM_STATE},
            chartDataKeys: [],
            chartDataSource: [],
            chartDataModelGrouped: [],
            weekAcceptance: [],
            lastOrderDraft: [],
            startYear: '',
            endYear: '',
            chartUrl: '/order/drafts/week/report'
        }
    },
    beforeCreate() {
        moment.locale('en', {
            week: {dow: 1} // Monday is the first day of the week
        });
    },
    methods: {
        async handleReportFilterSubmit() {
            if(this.$global.hasRole('suppliercarrier') && !this.$global.hasPermission('orderdraftscanseechartsc')) {
                return false;
            }

            this.formErrors = new Error({})
            this.chartDataModelGrouped = []
            this.chartDataKeys = []
            this.chartDataSource = []
            this.weekAcceptance = []
            this.lastOrderDraft = []

            try {
                const response = await request({
                    url: this.chartUrl,
                    method: 'post',
                    data: {...this.chartFormFields}
                })

                const {data, keys, start_year, end_year, last_order_draft} = response.data
                this.chartDataKeys = keys
                this.startYear = start_year
                this.endYear = end_year
                this.chartDataSource = data
                this.lastOrderDraft = last_order_draft
                const pickLocation = []

                _.map(data, (countryWise, index) => {
                    // Location wise group
                    let temp = []
                    _.map(countryWise, (item) => {
                        let className = '';
                        let titleName = '';
                        if(item.confirmation_request) {
                            if(item.confirmation_request.accept_full < 1 && item.confirmation_request.accept_contractual_difference < 1 && item.confirmation_request.accept_contractual < 1 && item.confirmation_request.cancel < 1) {
                                if(item.confirmation_request.first_reminder_sent > 0) {
                                    className = 'text-primary';
                                    titleName = this.$t('title.atnConfirmationPending');
                                }
                                if(item.confirmation_request.second_reminder_sent > 0) {
                                    className = 'text-primary';
                                    titleName = this.$t('title.atnConfirmationPending') + ' | ' +this.$t('title.atnReminderSent');
                                }
                            } else {
                                className = 'text-success';
                                titleName = this.$t('title.atnFullyConfirmed');
                                if(item.confirmation_request.accept_contractual_difference > 0) {
                                    className = 'text-warning';
                                    titleName = this.$t('title.atnFullyAcceptedWithOffer');
                                }
                                if(item.confirmation_request.accept_contractual > 0) {
                                    className = 'text-info';
                                    titleName = this.$t('title.atnPartiallyAccepted');
                                }
                                if(item.confirmation_request.cancel > 0) {
                                    className = 'text-danger';
                                    titleName = this.$t('title.atnRejected');
                                }
                            }
                            this.weekAcceptance.push({identifier: item.identifier, class: className, title: titleName});
                        }

                        //temp, if any additional request like this, we should look for a permanent solution on Location level
                        item.city = item.city.includes('Cervignano del Friuli') ? item.city.replace('Cervignano del Friuli', 'Cervignano') : item.city;
                        item.city = item.city.includes('S. Nicola di Melfi') ? item.city.replace('S. Nicola di Melfi', 'Melfi') : item.city;

                        const index = _.findIndex(temp, {city: item.city})
                        if (index >= 0) {
                            temp[index].items.push(item)
                        } else {
                            temp.push({city: item.city, items: [item]})
                        }
                    })

                    // model wise group
                    _.map(temp, (pickUpLocation, index) => {
                        let models = []
                        _.map(pickUpLocation.items, (item) => {
                            const modelName = item.model ? item.model.title : item.model_code
                            const index = _.findIndex(models, {model_code: modelName})
                            if (index >= 0) {
                                let modelExistInWeek = false
                                _.map(models, (model_items) => {
                                    _.map(model_items.items, (model_item) => {
                                        if(model_item.model_code == item.model_code && model_item.week_number == item.week_number) {
                                            model_item.total_orders += item.total_orders;
                                            modelExistInWeek = true
                                        }
                                    })
                                })
                                if(!modelExistInWeek) {
                                    models[index].items.push(item)
                                }
                            } else {
                                models.push({model_code: modelName, items: [item]})
                            }
                        })
                        temp[index].items = models
                    })
                    pickLocation.push({country: index, items: temp})
                })
                this.chartDataModelGrouped = pickLocation
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }

                this.handleServerError(error)
            }
        },
        handleNextClick() {
            this.chartFormFields.type = 'next'
            this.chartFormFields.start_date = moment(this.chartFormFields.start_date, 'YYYY-MM-DD').add(56, 'days').format('YYYY-MM-DD')
            this.handleReportFilterSubmit()
        },
        handlePrevClick() {
            this.chartFormFields.type = 'prev'
            this.chartFormFields.start_date = moment(this.chartFormFields.start_date, 'YYYY-MM-DD').subtract(56, 'days').format('YYYY-MM-DD')
            this.handleReportFilterSubmit()
        }
    }
}

